import "./assets/css/App.css";
import Header from "./components/ui/Header";
import Projects from "./pages/Projects";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import Footer from "./components/ui/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/projects" element={<Projects />}></Route>
          <Route exact path="/contact" element={<Contact />}></Route>
          <Route path="/*" element={<Error />}></Route>
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
