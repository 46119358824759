import { useState } from "react";
import { FaBars, FaMoon, FaSun } from "react-icons/fa";
import { useGlobalContext } from "../../context/context";
import { Link } from "react-router-dom";
import "../../assets/css/Navbar.css";

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);
  const { theme, setTheme } = useGlobalContext();

  const changeTheme = () => {
    if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("dark");
    }
  };

  const toggleShowLinks = () => {
    if (!showLinks) {
      setShowLinks(true);
    } else {
      setShowLinks(false);
    }
  };
  return (
    <nav className="navbar glass" data-theme={theme}>
      <div className="center">
        <div className="space-between">
          <div className="navbar__logo">
            <Link to="/" className="underline-hover">
              <h3>SG</h3>
            </Link>
          </div>
          <div className="navbar__toggle-btn" onClick={toggleShowLinks}>
            <FaBars />
          </div>
        </div>
        <div
          className={`${
            showLinks
              ? "navbar__links-container show-links"
              : "navbar__links-container"
          }`}
        >
          <ul className="navbar__links">
            <li>
              <Link to="/" className="underline-hover">
                Home
              </Link>
            </li>
            <li>
              <Link to="/projects" className="underline-hover">
                Projects
              </Link>
            </li>
            <li>
              <Link to="/contact" className="underline-hover">
                Contact
              </Link>
            </li>
            <li>
              <div
                className="navbar__mobile-darkmode-btn"
                onClick={changeTheme}
              >
                {theme === "light" ? (
                  <FaMoon className="icon" />
                ) : (
                  <FaSun className="icon" />
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="navbar__darkmode-btn" onClick={changeTheme}>
        {theme === "light" ? (
          <FaMoon className="icon" />
        ) : (
          <FaSun className="icon" />
        )}
      </div>
    </nav>
  );
};

export default Navbar;
