import React from "react";
import "../../assets/css/ProjectCard.css";

const Project = ({ linkUrl = "", image, name, categoryTags, techTags }) => {
  return (
    <article className="project-card glass">
      <a href={linkUrl}>
        {image?.fields?.file?.url && (
          <img
            className="project-card__img"
            src={image.fields.file.url}
            alt={name}
          />
        )}
        <div className="project-card__title">
          <h2>{name}</h2>
        </div>
        <div className="project-card__tag-container">
          {categoryTags?.length &&
            categoryTags.map((item, index) => (
              <div className="project-card__tag__category" key={index}>
                {item}
              </div>
            ))}
          {techTags?.length &&
            techTags.map((item, index) => (
              <div className="project-card__tag__tech" key={index}>
                {item}
              </div>
            ))}
        </div>
      </a>
    </article>
  );
};

export default Project;
