import { useState, useEffect } from "react";
import ProjectCard from "./ProjectCard";
import { useGlobalContext } from "../../context/context";
import { useLocation } from "react-router-dom";
import useContentful from "../../hooks/useContentful";
import "../../assets/css/ProjectList.css";

const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const { getProjects } = useContentful();
  const { theme } = useGlobalContext();
  const location = useLocation();
  const [showProjects, setShowProjects] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getProjects();
      if (response) {
        setProjects(response);
      }
    };

    fetchData();
  }, [getProjects]);

  useEffect(() => {
    if (location.pathname === "/projects") {
      setShowProjects(true);
    } else {
      setShowProjects(false);
    }
  }, [location]);

  return (
    <section data-theme={theme} className="project-cards__container">
      <h2>My projects</h2>
      <div
        className={`${
          showProjects ? "project-cards show-content" : "project-cards"
        }`}
      >
        {projects.length === 0 ? (
          <h3>
            <a className="link" href="https://github.com/samu20108">
              Check my projects from GitHub --&gt;
            </a>
          </h3>
        ) : (
          projects.map((item, index) => {
            return <ProjectCard key={index} {...item} />;
          })
        )}
      </div>
    </section>
  );
};
export default ProjectList;
