import { useRef, useEffect, useState } from "react";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";
import { useGlobalContext } from "../context/context";
import { useLocation } from "react-router-dom";
import "../assets/css/Contact.css";

const Contact = () => {
  const { theme } = useGlobalContext();
  const nameInput = useRef(null);

  useEffect(() => {
    nameInput.current.focus();
  }, []);
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (location.pathname === "/contact") {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [location]);

  return (
    <main>
      <section data-theme={theme} className="contact__container">
        <h1>Contact me</h1>
        <div
          className={`${
            showForm ? "form-container glass show-content" : "form-container glass"
          }`}
        >
          <form action="https://formspree.io/f/meqprvrd" method="POST">
            <label htmlFor="name">Name:</label>
            <input type="text" name="name" ref={nameInput} required></input>
            <label htmlFor="email">Email:</label>
            <input type="email" name="email" required></input>
            <label htmlFor="message">Message</label>
            <div className="textarea__wrapper">
              <textarea name="message" rows="6" cols="40" required></textarea>
            </div>
            <button className="btn" type="submit">
              Send
            </button>
          </form>

          <div className="social-media-links">
            <a href="https://www.linkedin.com/in/samuelgemmell/">
              <FaLinkedinIn />
            </a>
            <a href="https://github.com/samu20108">
              <FaGithub />
            </a>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Contact;
