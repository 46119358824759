import { useGlobalContext } from "../context/context";
import { Link } from "react-router-dom";
import "../assets/css/Error.css";

export default function Error() {
  const { theme } = useGlobalContext();

  return (
    <section data-theme={theme} className="error-section">
      <div className="error-message">
        <h2>Page not found</h2>
        <Link to="/">Back to Home</Link>
      </div>
    </section>
  );
}
