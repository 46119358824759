import { createClient } from "contentful";
import { useCallback } from "react";

const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
const spaceID = process.env.REACT_APP_CONTENTFUL_SPACE;

const client = createClient({
  space: spaceID,
  accessToken: accessToken,
  environment: "master",
});

export default function useContentful() {
  const getProjects = useCallback(async () => {
    try {
      const data = await client.getEntries({
        content_type: "projectList",
        select: "fields",
      });
      const projects = data.items[0].fields.projects.map((item) => {
        return {
          ...item.fields,
        };
      });
      return projects;
    } catch (error) {
      console.log("Error fetching projects");
    }
  }, []);

  return { getProjects };
}
