import "../../assets/css/Footer.css";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";
import { useGlobalContext } from "../../context/context";

const Header = () => {
  const { theme } = useGlobalContext();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer glass" data-theme={theme}>
      <div className="footer__social-media-links">
        <a href="https://www.linkedin.com/in/samuelgemmell/">
          <FaLinkedinIn />
        </a>
        <a href="https://github.com/samu20108">
          <FaGithub />
        </a>
      </div>
      <div className="footer__copyright">
        <p>&copy; {currentYear} Samuel Gemmell</p>
        <div className="footer__source-code underline-hover">
          <p>
            <a href="https://github.com/samu20108/s-g.fi">View Source Code</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Header;
