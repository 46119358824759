import { useGlobalContext } from "../context/context";
import { Link } from "react-router-dom";
import TypingText from "react-typing-text";
import "../assets/css/Home.css";

export default function Home() {
  const { theme } = useGlobalContext();

  return (
    <main>
      <section data-theme={theme} className="home__container">
        <TypingText
          text="Hi, I'm Samuel and I'm currently studying Information Technology."
          typingInterval={50}
          className="home__welcome-text"
        />
        <div className="home__links">
          <Link to="/projects">Projects</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </section>
    </main>
  );
}
